import EstimateFieldOption from './EstimateFieldOption';
import { getValueFromDto as getValue } from './_helper';

export default class EstimateComparison {
	constructor(dto) {
		this.fieldOptionId = getValue(dto, 'fieldOptionId', 'string', null);
	}

	get workflowId() {
		return EstimateFieldOption.getWorkflowId(this.fieldOptionId);
	}
}
