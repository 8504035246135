import api from '@/api/index';
import { cacheFiles } from '@/api/sw/cacheFiles';
import { DocumentReference } from '@/models/Document';
import Estimate from '@/models/Estimate';
import EstimateFieldOption from '@/models/EstimateFieldOption';
import EstimateSection from '@/models/EstimateSection';
import EstimateWorkflow from '@/models/EstimateWorkflow';
import EstimateWorkflows from '@/models/EstimateWorkflows';
import EstimateWorkflowSummary from '@/models/EstimateWorkflowSummary';
import Lead from '@/models/Lead';
import { DateTime } from 'luxon';

// initial state
const state = () => ({
	workflows: null,
	fields: null,
	fieldOrder: null,
	lead: null,
	estimate: null,
	hoverJob: null,
	newContractId: null,
});

// getters
const getters = {
	getWorkflowById: (state) => (workflowId) => state.workflows.find(x => x.id === workflowId),
	getSectionsByWorkflowId: (state, getters) => (workflowId) => {
		const workflow = getters.getWorkflowById(workflowId);
		if (!workflow) { return []; }
		return workflow.sections;
	},
	getLastCompleteSectionId: (state) => (workflowId) => state.estimate ? (state.estimate.workflowSummaries[EstimateWorkflows.getKey(workflowId)] || new EstimateWorkflowSummary()).lastCompleteSectionId : null,
	getOptionalsSectionsIdsAdded: (state) => (workflowId) => state.estimate ? (state.estimate.workflowSummaries[EstimateWorkflows.getKey(workflowId)] || new EstimateWorkflowSummary()).optionalsSectionsIdsAdded : null,
	estimateSteps: (state, getters) => (workflowId) => {
		if (!state.estimate) { return []; }
		const workflowKey = EstimateWorkflows.getKey(workflowId);
		const sections = getters.getSectionsByWorkflowId(workflowId);
		let estimateSteps = sections.filter(x => x.enabled && x.order > 0);
		const lastCompleteSectionId = getters.getLastCompleteSectionId(workflowId);
		const optionalsSectionsIdsAdded = getters.getOptionalsSectionsIdsAdded(workflowId);
		const currentStepOrder = lastCompleteSectionId ? (sections.find((x) => x.id === lastCompleteSectionId) || {}).order || 0 : 0;
		// find the index of the last enabled step with an order <= currentStepOrder
		const currentStepIndex = estimateSteps.reduceRight((found, x, i) => (found < 0 && x.order <= currentStepOrder ? i : found), -1);

		estimateSteps = estimateSteps.filter(x => !x.optional || optionalsSectionsIdsAdded.includes(x.id));

		for (let i = 0; i < estimateSteps.length; i++) {
			const step = estimateSteps[i];

			const info = {
				id: step.id,
				name: step.name,
				url: `/estimator/estimate/${state.estimate.id}/${workflowKey}/${step.order}`,
				order: step.order,
				complete: i <= currentStepIndex,
				lastComplete: i == currentStepIndex,
				current: i == currentStepIndex + 1,
				next: i === currentStepIndex + 2,
				unlocked: i <= currentStepIndex + 2,
				optional: step.optional,
			};
			if (i === estimateSteps.length - 1 && info.complete) {
				info.current = true;
			}
			estimateSteps[i] = info;
		}
		return estimateSteps;
	},
	getCurrentStepIndex: (state, getters) => (workflowId, steps) => {
		if (!Array.isArray(steps)) {
			steps = getters.estimateSteps(workflowId);
		}
		return steps.findIndex(x => x.current);
	},
	getCurrentStep: (state, getters) => (workflowId, steps) => {
		if (!Array.isArray(steps)) {
			steps = getters.estimateSteps(workflowId);
		}
		const index = getters.getCurrentStepIndex(workflowId, steps);
		return index >= 0 ? steps[index] : null;
	},
	isNextStep: (state, getters) => (workflowId, sectionId, steps) => {
		if (!Array.isArray(steps)) {
			steps = getters.estimateSteps(workflowId);
		}
		// get the current step index
		let currentStepIndex = getters.getCurrentStepIndex(workflowId, steps);
		let newIndex = steps.findIndex(x => x.id === sectionId);
		return (currentStepIndex === -1 && newIndex >= 0) || (currentStepIndex >= 0 && newIndex >= currentStepIndex);
	},
	stepIsComplete: (state, getters) => (workflowId, sectionId, steps) => {
		if (!Array.isArray(steps)) {
			steps = getters.estimateSteps(workflowId);
		}
		return steps.findIndex(x => x.id === sectionId && x.complete) >= 0;
	},
	getNextStep: (state, getters) => (workflowId, sectionId, steps) => {
		if (!Array.isArray(steps)) {
			steps = getters.estimateSteps(workflowId);
		}
		const index = steps.findIndex(x => x.id === sectionId);
		if (index < 0 || index === steps.length - 1) { return null; }
		return steps[index + 1];
	},
	getHoverJob: (state) => {
		return state.hoverJob;
	},
};

// mutations
const mutations = {
	setWorkflows(state, workflows) {
		if (Array.isArray(workflows)) {
			state.workflows = workflows;
		} else {
			state.workflows = null;
		}
	},
	setWorkflow(state, workflow) {
		if (workflow !== null && workflow instanceof EstimateWorkflow && state.workflows) {
			if (workflow.id in state.workflows) {
				// const sections = state.workflows[workflow.id].sections;
				// workflow.sections = sections;
				state.workflows[workflow.id] = workflow;
			}
		}
	},
	setSection(state, section) {
		if (section !== null && section instanceof EstimateSection && state.workflows) {
			const workflowId = section.workflowId;
			if (workflowId in state.workflows) {
				const sectionIndex = state.workflows[workflowId].sections.findIndex(x => x.id === section.id);
				if (sectionIndex >= 0) {
					state.workflows[workflowId].sections[sectionIndex] = section;
				}
			}
		}
	},
	setFields(state, fields) {
		if (fields !== null && typeof fields === 'object') {
			state.fields = fields;
		} else {
			state.fields = null;
		}
	},
	setFieldOrder(state, fieldOrder) {
		if (fieldOrder !== null && typeof fieldOrder === 'object') {
			state.fieldOrder = fieldOrder;
		} else {
			state.fieldOrder = null;
		}
	},
	setFieldOption(state, option) {
		if (option !== null && option instanceof EstimateFieldOption && state.fields) {
			const fieldId = option.fieldId;
			if (fieldId in state.fields) {
				const optionIndex = state.fields[fieldId].options.findIndex(x => x.id === option.id);
				if (optionIndex >= 0) {
					state.fields[fieldId].options[optionIndex] = option;
				}
			}
		}
	},
	setLead(state, lead) {
		if (lead !== null && lead instanceof Lead) {
			state.lead = lead;
		} else {
			state.lead = null;
		}
	},
	setEstimate(state, estimate) {
		if (estimate !== null && estimate instanceof Estimate) {
			estimate.refreshItemTree();
			state.estimate = estimate;
		} else {
			state.estimate = null;
		}
	},
	addEstimateDocument(state, document) {
		if (state.estimate !== null && document instanceof DocumentReference && document.estimateId === state.estimate.id) {
			const index = state.estimate.documents.findIndex((x) => x.id === document.id);
			if (index >= 0) {
				state.estimate.documents.splice(index, 1, document);
			} else {
				state.estimate.documents.push(document);
			}
		}
	},
	removeEstimateDocument(state, documentId) {
		if (state.estimate !== null) {
			const index = state.estimate.documents.findIndex((x) => x.id === documentId);
			if (index >= 0) {
				state.estimate.documents.splice(index, 1);
			}
		}
	},
	setHoverJob(state, hoverJob) {
		state.hoverJob = hoverJob;
	},
	setHoverJobMeasurements(state, measurements) {
		if (state.hoverJob) {
			state.hoverJob.measurements = measurements;
		}
	},
	setNewContractId(state, id) {
		if (id === null || typeof id === 'number') {
			state.newContractId = id;
		}
	},
	async setOptionalSectionsAdded(state, obj) {
		const e = state.estimate;
		e.getWorkflowSummaryByKey(obj.value);

		const initial = state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded;
		if (state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded.includes(obj.id)) {
			state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded = state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded.filter(x => x !== obj.id);
		}
		else {
			state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded.push(obj.id);
		}

		state.estimate.modifiedTime = DateTime.now();
		const response = await api.estimates.update(e);
		if (!response instanceof Estimate) {
			api.helpers.handleHttpError(response);
			state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded = initial;
		}
	},
};

// actions
const actions = {
	async clear({ commit }) {
		commit('setWorkflows', null);
		commit('setFields', null);
		commit('setLead', null);
		commit('setEstimate', null);
		commit('setHoverJob', null);
		commit('setNewContractId', null);
	},
	async refresh({ commit, rootGetters }) {
		if (rootGetters['auth/isUser']) {
			const p1 = api.estimateWorkflows.getAll();
			const p2 = api.estimateSections.getAll();
			const p3 = api.estimateFields.getAll();
			await Promise.all([p1, p2, p3]);
			const response1 = await p1;
			const workflowMap = {};
			if (Array.isArray(response1)) {
				for (let i = 0; i < response1.length; i++) {
					const workflow = response1[i];
					workflowMap[workflow.id] = workflow;
				}
			}
			const response2 = await p2;
			if (Array.isArray(response2)) {
				for (let i = 0; i < response2.length; i++) {
					const section = response2[i];
					const workflow = workflowMap[section.workflowId];
					if (!workflow.enabled) {
						section.enabled = false;
					}
					workflow.sections.push(section);
				}
			}
			for (const [, workflow] of Object.entries(workflowMap)) {
				workflow.sections.sort((a, b) => a.order - b.order);
			}
			const response3 = await p3;
			const files = [];
			// this variant topological sorting could be saved server-side and sent to the client with the
			const fieldVariantGraph = {};
			for (const workflowId of Object.keys(EstimateWorkflows.map)) {
				fieldVariantGraph[workflowId] = {
					incomingEdges: {},
					outgoingEdges: {},
				};
			}

			const fields = {};
			if (Array.isArray(response3)) {
				for (let i = 0; i < response3.length; i++) {
					const field = response3[i];
					fields[field.id] = field;
					if (field.variants.length > 0) {
						const incomingEdges = fieldVariantGraph[field.workflowId].incomingEdges;
						const outgoingEdges = fieldVariantGraph[field.workflowId].outgoingEdges[field.id] = new Set();
						for (const fieldId of Object.keys(field.variants[0].optionsMap)) {
							if (fieldId && fieldId !== field.id) {
								outgoingEdges.add(fieldId);
								const inList = (fieldId in incomingEdges) ? incomingEdges[fieldId] : (incomingEdges[fieldId] = new Set());
								inList.add(field.id);
							}
						}
					}

					if (process.env.NODE_ENV === 'production') {
						for (let i = 0; i < field.options.length; i++) {
							const image = field.options[i].image;
							if (image != null) {
								files.push(image);
							}
						}
					}
				}
			}

			const fieldOrder = {};
			for (const workflowId of Object.keys(fieldVariantGraph)) {
				const { incomingEdges, outgoingEdges } = fieldVariantGraph[workflowId];
				const sorted = []; // Empty list that will contain the sorted elements
				const todo = Object.keys(outgoingEdges).filter(x => !(x in incomingEdges)); // Set of all nodes with no incoming edge

				// while todo is not empty do
				while (todo.length > 0) {
					// remove a node n from todo
					const n = todo.shift();
					// add n to sorted
					sorted.push(n);
					if (!(n in outgoingEdges)) continue;
					for (const m of outgoingEdges[n]) {
						// remove edge e from the graph
						incomingEdges[m].delete(n);
						// if m has no other incoming edges then
						if (incomingEdges[m].size == 0) {
							// insert m into todo
							delete incomingEdges[m];
							todo.push(m);
						}
					}
					delete outgoingEdges[n];
				}
				// sorted is a topological sort of the graph nodes
				fieldOrder[workflowId] = sorted.reverse();
			}

			cacheFiles(files);
			const workflows = EstimateWorkflows.list.map(x => workflowMap[x.id]);
			commit('setWorkflows', workflows);
			commit('setFields', fields);
			commit('setFieldOrder', fieldOrder);
		} else {
			commit('setWorkflows', null);
			commit('setFields', null);
			commit('setFieldOrder', null);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
