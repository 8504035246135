import companies from './modules/companies';
import documents from './modules/documents';
import estimateFieldOptions from './modules/estimateFieldOptions';
import estimateFields from './modules/estimateFields';
import estimates from './modules/estimates';
import estimateSections from './modules/estimateSections';
import estimateWorkflows from './modules/estimateWorkflows';
import files from './modules/files';
import helpers from './modules/helpers';
import info from './modules/info';
import integrations from './modules/integrations';
import leads from './modules/leads';
import localChanges from './modules/localChanges';
import settings from './modules/settings';
import countries from './modules/countries';
import states from './modules/states';
import timeZones from './modules/timeZones';
import users from './modules/users';
import zohoUsers from './modules/zohoUsers';
import frequencies from './modules/frequencies';

export default {
	localChanges,
	companies,
	documents,
	estimateFields,
	estimateFieldOptions,
	estimates,
	estimateSections,
	estimateWorkflows,
	files,
	helpers,
	info,
	integrations,
	leads,
	settings,
	countries,
	states,
	timeZones,
	users,
	zohoUsers,
	frequencies,
};
