import { getValueFromDto as getValue } from './_helper';
import EstimateSections from './EstimateSections';

export default class EstimateSection {
	constructor(dto) {
		this.setDynamicData(dto);
	}

	setDynamicData(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.workflowId = getValue(dto, 'workflowId', 'number', 0);
		this.enabled = getValue(dto, 'enabled', 'boolean', false);
		this.description = getValue(dto, 'description', 'string', '');
		this.optional = getValue(dto, 'optional', 'boolean', false);
	}

	get name() {
		return EstimateSections.getName(this.id);
	}

	get order() {
		return EstimateSections.getOrder(this.id);
	}
}
